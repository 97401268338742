import React, { useEffect } from "react";

import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import PageTitle from "../components/PageTitle";
import PreRegisterSection from "../components/PreRegisterSection";
import scrollTop from "../utils/scrollTop";
import { sikamaBanner } from "../utils/images";

function AboutPage() {
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <main>
      <AppHeader />

      <PageTitle heading="About Us" subheading="" />

      <section className="sectionPadding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="aboutImg">
                <img alt="" src={sikamaBanner} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="aboutContent">
                <h4>
                  About <strong>Sikama </strong>
                  financial services
                </h4>
                <p>
                  Sikama Financial Services, a Fintech company, was established
                  to revolutionize how people transfer funds by creating
                  innovative technology solutions. Specializing in developing a
                  cutting-edge non-bank money transmitter software application,
                  Sikama aims to provide customers with a seamless experience to
                  transfer funds between family and friends. At the same time,
                  engaging in real-time interactions such as chatting, calling,
                  or voice messaging.
                </p>
                <p>
                  Sikama Financial Services was founded by a group of
                  experienced fintech professionals. At Sikama Financial
                  Services, we work with a diverse team of experts in software
                  development, financial services, user experience design, and
                  customer relationship management. In the rapidly evolving
                  financial technology landscape, Sikama Financial Services is a
                  pioneer in reimagining how financial transactions occur
                  between friends and family.
                </p>
                <p>
                  The company is dedicated to continuous improvement, user
                  satisfaction, and regulatory compliance. As Sikama continues
                  to refine its software application and expand its services, it
                  remains dedicated to its mission of providing secure,
                  convenient, and innovative solutions for transferring funds
                  and fostering meaningful connections among users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionPadding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="missionContent">
                <h4>our Mission</h4>
                <p>
                  We aim to offer fast, secure, affordable money transfers with
                  exceptional customer service, leveraging innovative technology
                  while ensuring compliance and financial inclusion.
                </p>
              </div>

              <div className="missionContent mt-5">
                <h4>our Vision</h4>
                <p>
                  To be a global leader in money transfer services, connecting
                  people and economies worldwide.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="missionContent">
                <h4>our Core Values</h4>
                <ul>
                  <li>
                    <strong>People : </strong>
                    We value our people with a strong belief in the “A company
                    is its people” philosophy and provide opportunities to
                    perform to their full potential.
                  </li>
                  <li>
                    <strong>Integrity : </strong>
                    We know that by embracing differences, actively including
                    various voices, and joining with our partners, we can solve
                    the world's most complex problems. We act in a right and
                    ethical way in all manners, ensuring fairness with honor and
                    grace.
                  </li>
                  <li>
                    <strong>Excellence : </strong>
                    We challenge ourselves to the highest level of learning and
                    performance, tapping the best of the human spirit to create
                    impact. We give our best efforts with endless passion and a
                    challenging spirit to become the world's best in every way.
                  </li>
                  <li>
                    <strong>Co-Prosperity : </strong>
                    We take full responsibility as good corporate citizens in
                    pursuit of mutual prosperity with our community, nation, and
                    human society. We take responsibility and ownership for our
                    actions and decisions, endorsing a blame-free culture.
                  </li>
                  <li>
                    <strong>Fun : </strong>
                    We promote the company ethos of an enjoyable working
                    environment and always strive to be positive.
                  </li>
                  <li>
                    <strong>Innovation : </strong>
                    Our organizational climate encourages, supports, and rewards
                    creativity and innovation. We identify, develop, and deploy
                    leading-edge technology and provide employees with the
                    flexibility and freedom to generate original ways of
                    thinking.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sectionPadding mt-5 mobileNone"></div>

      <PreRegisterSection />

      <AppFooter />
    </main>
  );
}

export default AboutPage;
