import React from "react";

function PageTitle({ heading, subheading }) {
  return (
    <div className="appbreadcrumb">
      <h3>{heading}</h3>
      <h6>{subheading}</h6>
    </div>
  );
}

export default PageTitle;
