import React, { useEffect } from "react";

import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import PageTitle from "../components/PageTitle";
import PreRegisterSection from "../components/PreRegisterSection";
import scrollTop from "../utils/scrollTop";

function TermsPage() {
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <main>
      <AppHeader />

      <PageTitle
        heading="Terms & Conditions"
        subheading="Terms & Conditions are legal agreements that outline the rules, responsibilities, and obligations of parties involved in a transaction or service, and are important to ensure that all parties understand and agree to the terms before proceeding with the transaction or service"
      />

      <section className="sectionPadding">
        <div className="container privacyContainer">
          <h4>Terms of Service</h4>
          <p>
            The search results provided do not directly address the Terms of
            Service for Sikama Financial Services. If you have a specific
            question or need information related to the privacy policy or terms
            of service of Sikama Financial Services, please provide more details
            so I can assist you accurately.
          </p>
          <h4>1. Introduction</h4>
          <h6>1.1 Purpose of the Terms of Service</h6>
          <p>
            The purpose of the Terms of Service is to establish a legal
            agreement between Sikama Financial Services, LLC (“Sikama”) and its
            users. This agreement outlines the rules and guidelines for using
            Sikama’s services, setting clear expectations for both parties. It
            covers the scope of services offered, user rights and
            responsibilities, fees and payments, privacy policies, and the
            conditions under which the Service can be accessed and used.
            Additionally, the Terms of Service protect Sikama’s intellectual
            property and sets forth the legal obligations of users, including
            compliance with applicable laws and regulations. It is designed to
            ensure a safe, reliable, and compliant environment for conducting
            financial transactions, safeguarding both the company and its
            customers.
          </p>
          <h6>1.2 Acceptance of Terms</h6>
          <p>
            By accessing our website, www.sikamai.com, or our mobile app, you
            agree to abide by these Terms of Service. If you do not agree with
            any part of these terms, you must discontinue using our services
            immediately. Your continued use of our services will constitute your
            acceptance of any changes or revisions to our Terms of Service. We
            highly recommend reading this document carefully, understanding it
            fully, and consulting with a legal advisor if necessary.
          </p>
          <p>
            Please note that this agreement affects your legal rights and
            obligations. If you disagree to be bound by these Terms of Service,
            do not access or use our services. We appreciate your trust and will
            work hard to provide you with top-tier services within the confines
            of this agreement.
          </p>
          <h4>2. Definitions</h4>
          <h6>2.1 Definition of Key Terms</h6>
          <p>
            Throughout these Terms of Service, certain key terms that carry
            specific definitions are used. These terms include but are not
            limited to “Sikama,” which refers to Sikama Financial Services, LLC,
            headquartered in Owatonna, MN.; “User,” which denotes any individual
            or entity that registers an account with Sikama or uses our services
            in any capacity; “Services,” which encapsulates all the services
            Sikama provides including cross-border payments, P2P remittance
            services, on-ramp and off-ramp of stable assets; and “Content,”
            which pertains to any text, graphics, user interfaces, visual
            interfaces, photographs, trademarks, logos, sounds, music, artwork,
            and computer code, including but not limited to the design,
            structure, selection, coordination, expression, “look and feel,” and
            arrangement of such Content contained on the Site. Further
            definitions may be provided throughout the document as required.
          </p>
          <h6>2.2 Clarification of User Categories</h6>
          <p>
            We distinguish between different categories of users to help clarify
            rights and responsibilities under these Terms of Service.
            “Registered Users” have created an account with Sikama, while “Guest
            Users” visit our website or mobile app and access our services
            without registering an account. “Business Users” are registered
            users who use our services for business or commercial purposes,
            while “Individual Users” are registered users who use our services
            for personal, non-commercial purposes.
          </p>
          <h6>2.3 Explanation of Services Provided</h6>
          <p>
            Our “Services” comprise a range of financial offerings, each with
            unique features and functions. The term “Cross-border Payments”
            refers our services for transferring money across national
            boundaries. “Payment Settlements Service” refers to our services for
            facilitating the completion of transactions, and “On-ramp and
            Off-ramp Stable Assets” pertains to our services enabling the
            exchange between fiat and stable assets and “Peer-to-Peer (P2P)
            remittance services refers to remittance sent and received via our
            website and/or mobile app. Understanding each Service you use is
            important, as different terms and conditions may apply.
          </p>
          <h4>3. User Registration and Account</h4>
          <h6>3.1 Eligibility Criteria</h6>
          <p>
            To use the services provided by Sikama, you must first register an
            account with us. To be eligible to create an account, you must be at
            least 18 years old or of the legal age to form a binding contract in
            your jurisdiction if that age is greater than 18. By using our
            services, you represent and warrant that you meet all eligibility
            requirements outlined in these Terms of Service. In its sole
            discretion, Sikama may refuse to offer its services to any person or
            entity and change its eligibility criteria at any time.
          </p>
          <h6>3.2 Account Registration Process</h6>
          <p>
            Registration requires you to provide certain information, including
            but not limited to your full legal name, a valid email address, and
            any other information indicated as necessary. You agree to provide
            accurate, current, and complete information during the registration
            process and to update such information as it changes. Sikama
            reserves the right to suspend or terminate your account if any
            information provided during the registration process or thereafter
            proves to be inaccurate, not current, or incomplete.
          </p>
          <h6>3.3 Account Security Obligations</h6>
          <p>
            You are responsible for maintaining the confidentiality of your
            account password and all activities under your account. You agree
            not to disclose your password to any third party, and you will
            notify Sikama immediately if your password is lost, stolen, or
            disclosed to an unauthorized third party or if there is any
            unauthorized use of your account or any breach of security.
          </p>
          <h6>3.4 Consequences of Violating Account Security</h6>
          <p>
            Any misuse of your account, including, without limitation, failing
            to maintain account security, may result in immediate account
            termination and potential legal action. Sikama is not liable for any
            loss or damage arising from your failure to comply with the above
            requirements. You understand that keeping your Sikama account
            information confidential is important to protect your personal data
            and financial assets and prevent unauthorized access to your
            account.
          </p>
          <h4>4. Use of Services</h4>
          <h6>4.1 User Responsibilities</h6>
          <p>
            As a user, you are responsible for using our services in a manner
            that is respectful, legal, and adheres to these Terms of Service.
            You commit to not using our services for any illicit activities,
            including but not limited to fraud, money laundering, and the
            financing of terrorism. You further agree to comply with all
            applicable laws, rules, and regulations in your jurisdiction.
          </p>
          <h6>4.2 Conduct Requirements</h6>
          <p>
            Your conduct while using our services should reflect a standard of
            respect and professionalism. This includes refraining from posting
            or sharing any content that is defamatory, abusive, harassing,
            threatening, or otherwise violates the legal rights of others. You
            also agree not to introduce malicious software or harmful technical
            operations to our website or mobile app.
          </p>
          <h6>4.3 Prohibited Activities</h6>
          <p>
            Prohibited activities include, but are not limited to, actions that:
            violate any laws, infringe upon the rights of others, or harm or
            threaten the safety of others; involve attempting to gain
            unauthorized access to another user’s account; or involve creating a
            false identity or impersonating an employee or representative of
            Sikama. Violating these prohibitions may result in the termination
            of your account and further legal action as deemed necessary.
          </p>
          <h6>4.4 Service Limitations and Right to Modify Services</h6>
          <p>
            While Sikama endeavors to ensure its services are always available,
            there may be times when services are interrupted, including for
            scheduled maintenance or upgrades, for emergency repairs, or due to
            the failure of telecommunications links or equipment. Sikama
            reserves the right to remove any content or features from its
            services at any time, for any reason, and to modify or discontinue
            (temporarily or permanently) all or any part of its services with or
            without notice. Sikama will not be liable to you for any such
            modifications, suspensions, or discontinuance of its services.
          </p>
          <h4>5. Intellectual Property Rights</h4>
          <h6>5.1 Sikama’s Rights and Ownership of Content</h6>
          <p>
            All of the content on the Sikama website and mobile app, including
            all visuals, text, audio, and video clips, are subject to copyright
            protections and/or other intellectual property rights or licenses
            held by Sikama. The entire content of the Sikama website and mobile
            app is copyrighted as a collective work under U.S. and other
            copyright laws. Sikama owns a copyright in the arrangement,
            coordination, and enhancement of the content, as well as in the
            original content. You do not acquire any ownership rights by
            printing or downloading any portion of the content.
          </p>
          <h6>5.2 User’s Rights and Restrictions</h6>
          <p>
            You may access and use our services, and the content and software
            provided therein, for your own personal, non-commercial use. This
            access is provided as part of the Service and cannot be separated or
            used outside of this context without prior written consent from
            Sikama. You are not permitted to copy, reproduce, distribute,
            transmit, broadcast, display, sell, license, or otherwise exploit
            any content or software for any other purposes without the prior
            written consent of Sikama or the respective licensors of the
            content.
          </p>
          <h6>5.3 User Content and Licensing to Sikama</h6>
          <p>
            Any content you upload or otherwise provide to Sikama in connection
            with the services may be used by Sikama in various ways, including
            advertising and promotional. Therefore, by submitting such content,
            you automatically grant, represent and warrant that you have the
            right to grant, to Sikama an irrevocable, non-exclusive, fully-paid,
            royalty-free, transferable, sub-licensable, worldwide license to use
            such content in any manner.
          </p>
          <h6>5.4 Prohibited Content</h6>
          <p>
            Users are prohibited from posting, sharing, or transmitting any
            content that infringes or violates the intellectual property rights
            of any person or entity. This includes, but is not limited to,
            copyrights, patents, trade secrets, trademarks, or rights of
            publicity or privacy. Violation of these terms may result in the
            termination of your account and legal action.
          </p>
          <h4>6. Privacy and Security</h4>
          <h6>6.1 User Data Collection</h6>
          <p>
            When you use our services, Sikama may collect and store certain
            information about you to improve our services and for compliance
            purposes. This information may include personally identifiable
            information such as your name, email address, financial information,
            and non-personally identifiable information such as your browsing
            history and preferences. Our collection and use of this information
            is governed by our Privacy Policy, which forms part of these Terms
            of Service.
          </p>
          <h6>6.2 Data Usage and Sharing</h6>
          <p>
            Sikama uses your information to deliver our services, personalize
            your experience, conduct internal research, and maintain compliance
            with regulatory obligations. We do not sell or rent your personal
            information to third parties. We may share your information with
            trusted third-party service providers as necessary to provide you
            with services. Whenever we share your data, we take steps to ensure
            it is treated securely and in accordance with our privacy policy and
            applicable laws.
          </p>
          <h6>6.3 Data Security Measures</h6>
          <p>
            We implement a variety of security measures to ensure the safety of
            your personal information. When you enter, submit, or access your
            personal information, we offer the use of a secure server. All
            sensitive/credit information supplied by users is transmitted via
            Secure Socket Layer (SSL) technology and then encrypted into our
            payment gateway providers’ databases only to be accessible by
            authorized individuals with special access rights to such systems,
            which must keep the information confidential.
          </p>
          <h6>6.4 User’s Privacy Responsibilities</h6>
          <p>
            As a user, you have certain privacy responsibilities to help keep
            your data secure. You agree to keep your username and password
            confidential, to log out of your account at the end of each session,
            and to take measures to prevent unauthorized access to your account.
            If you suspect any unauthorized account use, you agree to notify
            Sikama immediately. Sikama will not be held responsible for any
            losses arising from unauthorized use of your account.
          </p>
          <h4>7. Financial Transactions and Services</h4>
          <h6>7.1 Transaction Authorization and Disputes</h6>
          <p>
            By using the financial services provided by Sikama, you authorize us
            to execute transactions according to your instructions. It is your
            responsibility to ensure the accuracy of your instructions. Any
            dispute over a financial transaction must be communicated to Sikama
            immediately, and we will endeavor to resolve such disputes promptly
            and fairly. However, Sikama cannot guarantee the reversal of a
            transaction due to the immutable nature of certain financial
            transactions, such as those involving cryptocurrencies.
          </p>
          <h6>7.2 Transaction Limitations and Fees</h6>
          <p>
            Certain limitations may be imposed on the transactions you can
            perform through our services, including daily or monthly limits on
            the amount that can be transferred. Sikama also reserves the right
            to charge fees for certain transactions or services. These fees, if
            any, will be disclosed to you in advance of the transaction, and by
            proceeding with the transaction, you agree to pay such fees.
          </p>
          <h6>7.3 Risk Acknowledgement</h6>
          <p>
            Financial transactions, particularly those involving currencies and
            cryptocurrencies, involve significant risks. Prices can and do
            fluctuate on any given day. Due to such price fluctuations, you may
            increase or lose value in your assets anytime. Any currency -
            digital or not - may be subject to large swings in value and may
            even become worthless. You agree that you are solely responsible for
            understanding the risks of utilizing our services.
          </p>
          <h6>7.4 Compliance with Laws and Regulations</h6>
          <p>
            Sikama is committed to strict compliance with all applicable laws
            and regulations relating to financial transactions and anti-money
            laundering. We will cooperate with law enforcement in all instances
            of suspected illegal activity on our platform. Users are also
            expected to abide by these laws and regulations, and any violation
            may result in account suspension or termination.
          </p>
          <h4>8. Fees and Payments</h4>
          <h6>8.1 Fee Structure</h6>
          <p>
            Sikama reserves the right to charge fees for certain features of its
            services. If a fee is charged for any part of our services, it will
            be clearly disclosed to you in connection with the part of the
            Service to which the fee relates. All fees are stated in U.S.
            dollars unless otherwise specified, and you are responsible for
            paying all fees and applicable taxes associated with our services in
            a timely manner with a valid payment method.
          </p>
          <h6>8.2 Fee Changes</h6>
          <p>
            In its sole discretion, Sikama may change its fee structure and/or
            notify users of material changes. If Sikama introduces a new service
            or modifies an existing service, the fees for that Service are
            effective at the launch or modification of the service. Unless
            otherwise stated, all fees are payable upon demand.
          </p>
          <h6>8.3 Payment Authorization</h6>
          <p>
            You are authorizing us to charge you the service fees by providing a
            payment method. Depending on where you transact from, the type of
            payment method used, and where your payment method was issued, your
            transaction with us may be subject to foreign exchange fees or price
            differences because of exchange rates. Sikama does not support all
            payment methods, currencies, or locations for payment and may vary
            its offerings from time to time.
          </p>
          <h6>8.4 Refund Policy</h6>
          <p>
            Unless otherwise required by Law or permitted by these Terms of
            Service, all purchases are final and non-refundable. If you believe
            that Sikama has charged you in error, you must contact us within 90
            days of such charge. No refunds will be given for any charges more
            than 90 days old. We reserve the right to refuse a refund request if
            we believe it is in violation of these Terms of Service or if we
            suspect fraudulent activity or misuse of our services.
          </p>
          <h4>9. Termination of Service</h4>
          <h6>9.1 Termination by User</h6>
          <p>
            You may terminate your relationship with Sikama at any time by
            closing your account through the account settings on our platform.
            Upon account closure, any pending transactions will be cancelled.
            You are responsible for ensuring all obligations, pending
            transactions, and fees are satisfied before you request account
            closure.
          </p>
          <h6>9.2 Termination by Sikama</h6>
          <p>
            Sikama reserves the right, in its sole discretion, to terminate your
            access to all or part of the services, with or without notice, for
            any reason or no reason at all, including, without limitation, for
            violation of these Terms of Service. Examples of activity that could
            lead to termination include attempting to gain unauthorized access
            to the services, violating laws, or failing to make payment of fees.
          </p>
          <h6>9.3 Effect of Termination</h6>
          <p>
            Upon termination of these Terms of Service for any reason, all
            rights and licenses granted to you under this agreement will
            immediately cease, and you will immediately cease all use of and
            access to the services. Any and all obligations and liabilities,
            which by their nature are intended to survive termination, will
            survive any expiration or termination of these Terms of Service.
          </p>
          <h6>9.4 User Content After Termination</h6>
          <p>
            Upon termination, it is your responsibility to secure all necessary
            personal data from your account before it is closed. After
            termination, Sikama will not be responsible for retaining any user
            content, personal data, transaction data or other information
            associated with your account. You agree that Sikama will have no
            liability to you or any third party for termination of your account,
            removal of your user content, or blocking of your access to our
            services.
          </p>
          <h4>10. Disclaimers and Limitations of Liability</h4>
          <h6>10.1 No Warranty</h6>
          <p>
            The services provided by Sikama are provided “as is,” without
            warranty of any kind. We make no guarantee, representation, or
            warranty regarding the timeliness, reliability, accuracy, or
            completeness of any services or content provided. Sikama expressly
            disclaims any warranties, express or implied, including but not
            limited to implied warranties of merchantability, fitness for a
            particular purpose, and non-infringement.
          </p>
          <h6>10.2 Limitation of Liability</h6>
          <p>
            To the maximum extent permitted by applicable Law, in no event will
            Sikama, its affiliates, officers, directors, employees, agents,
            suppliers, or licensors be liable for any direct, indirect,
            incidental, special, consequential, punitive, or exemplary damages,
            including but not limited to, damages for loss of profits, goodwill,
            use, data, or other intangible losses, resulting from your access to
            or use of or inability to access or use the services; any conduct or
            content of any third party on the services; any content obtained
            from the services; unauthorized access, use, or alteration of your
            transmissions or content, whether based on warranty, contract, tort
            (including negligence), or any other legal theory, whether or not
            Sikama has been informed of the possibility of such damage, and even
            if a remedy set forth herein is found to have failed of its
            essential purpose.
          </p>
          <h6>10.3 Indemnification</h6>
          <p>
            You agree to defend, indemnify, and hold harmless Sikama, its
            affiliates, licensors, and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses, or fees (including reasonable attorneys’ fees) arising out
            of or relating to your violation of these Terms of Service or your
            use of the services, including, but not limited to, your user
            content, any use of the services’ content, services, and products
            other than as expressly authorized in these Terms of Service, or
            your use of any information obtained from the services.
          </p>
          <h6>10.4 Risk of Using the Services</h6>
          <p>
            Your use of the services, including but not limited to your
            submission of content to the services, is at your sole risk. In
            addition, Sikama does not endorse, support, represent, or guarantee
            the completeness, truthfulness, accuracy, or reliability of any
            content or communications posted via the services or endorse any
            opinions expressed via the services. You understand that by using
            the services, you may be exposed to content that might be offensive,
            harmful, inaccurate, or otherwise inappropriate, and you agree that
            Sikama will not be liable for any damages you allege to incur as a
            result of such content.
          </p>
          <h4>11. Dispute Resolution and Governing Law</h4>
          <h6>11.1 Governing Law</h6>
          <p>
            These Terms of Service and any dispute or claim arising out of or
            related to these terms, their subject matter, or their formation (in
            each case, including non-contractual disputes or claims) shall be
            governed by and construed in accordance with the laws of the State
            of Minnesota, without giving effect to any choice or conflict of law
            provision or rule.
          </p>
          <h6>11.2 Agreement to Arbitrate</h6>
          <p>
            You and Sikama agree that any dispute, claim, or controversy between
            you and Sikama arising in connection with or relating in any way to
            these Terms of Service or to your relationship with Sikama as a user
            of the Service (whether based on contract, tort, statute, fraud,
            misrepresentation, or any other legal theory, and whether the claims
            arise during or after the termination of these Terms) will be
            determined by mandatory binding individual (not class) arbitration.
            You and Sikama further agree that the arbitrator shall have the
            exclusive power to rule on his or her own jurisdiction, including
            any objections with respect to the existence, scope, or validity of
            the arbitration agreement or to the arbitrability of any claim or
            counterclaim.
          </p>
          <h6>11.3 No Class Actions</h6>
          <p>
            You and Sikama agree that each may bring claims against the other
            only in your or its individual capacity and not as a plaintiff or
            class member in any purported class or representative action. Unless
            both you and Sikama agree, no arbitrator or judge may consolidate
            more than one person’s claims or otherwise preside over any form of
            a representative or class proceeding.
          </p>
          <h6>11.4 Exceptions</h6>
          <p>
            Despite the provisions of the “Agreement to Arbitrate” and “No Class
            Actions” above, you and Sikama both agree that nothing herein will
            be deemed to waive, preclude, or otherwise limit either of our
            rights, at any time, to (1) bring an individual action in a U.S.
            small claims court, (2) pursue enforcement actions through
            applicable U.S. federal, state, or local agencies where such actions
            are available, (3) seek injunctive relief in a court of Law, or (4)
            to file suit in a court of law to address intellectual property
            infringement claims.
          </p>
          <h4>12. Changes to the Terms of Service</h4>
          <h6>12.1 Right to Modify</h6>
          <p>
            Sikama reserves the right, at its sole discretion, to modify or
            replace these Terms of Service at any time. Changes will not apply
            retroactively and will become effective immediately after they are
            posted. However, changes addressing new functions for the services
            or changes made for legal reasons may be effective immediately upon
            notice.
          </p>
          <h6>12.2 Notification of Changes</h6>
          <p>
            Whenever we change our Terms of Service, we will post those changes
            to this Terms of Service page and other places we deem appropriate
            so our users are always aware of what information we collect, how we
            use it, and under what circumstances, if any, we disclose it. We may
            also send you a notice electronically to your registered email. You
            are advised to review this Terms of Service page periodically for
            any changes.
          </p>
          <h6>12.3 Acceptance of Changes</h6>
          <p>
            Your continued use of the services following the posting of any
            changes to these Terms of Service constitutes acceptance of those
            changes. If you disagree with the new terms, you should stop using
            the services and inform us immediately.
          </p>
          <h6>12.4 User Feedback</h6>
          <p>
            We welcome any feedback, comments, and suggestions you may have to
            improve our services. Please understand, however, that by providing
            feedback, you grant us a non-exclusive, worldwide, royalty-free,
            irrevocable, sub-licensable, perpetual license to use and publish
            those ideas and materials for any purpose without compensation to
            you.
          </p>
          <h4>13. Contact Information</h4>
          <h6>13.1 General Inquiries</h6>
          <p>
            For any general inquiries about our services, the practices of this
            site, or your experiences with this site, please contact us at
            support@sikamai.com. We are committed to addressing your concerns on
            time and will endeavor to respond as soon as possible.
          </p>
          <h6>13.2 Customer Support</h6>
          <p>
            For any issues related to your account or transactions, please
            contact our customer support team at support@sikamai.com. Please
            include a detailed description of the issue you’re experiencing, any
            error messages you may have received, and any other relevant
            information that may help us troubleshoot and resolve your issue.
          </p>
          <h6>13.3 Legal Department</h6>
          <p>
            Any notices of copyright infringement, requests for information
            related to legal matters, or other legal correspondence should be
            sent to our legal department at support@sikamai.com. Please include
            as much detail as possible in your message so we can properly
            address your inquiry.
          </p>
          <h6>13.4 Mailing Address</h6>
          <p>
            For written correspondence, please mail it to the following address:
          </p>
          <p>
            Sikama Financial Services, LLC 202 North Ceder Ave., Suite 1
            Owatonna, MN 55060 United States
          </p>
          <h6>13.5 Business Hours</h6>
          <p>
            Our regular business hours are Monday to Friday, 9:00 AM to 5:00 PM
            Eastern Time. Please note that response times may be delayed outside
            of these hours.
          </p>
          <p>
            Please note that your communication must be in English. We may be
            unable to respond to inquiries in languages other than English.
          </p>
        </div>
      </section>

      <section className="sectionPadding mt-5 mobileNone"></section>

      <PreRegisterSection />

      <AppFooter />
    </main>
  );
}

export default TermsPage;
