import React, { useEffect } from "react";

import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import PageTitle from "../components/PageTitle";
import PreRegisterSection from "../components/PreRegisterSection";
import scrollTop from "../utils/scrollTop";

function PrivacyPage() {
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <main>
      <AppHeader />

      <PageTitle
        heading="Privacy Policy"
        subheading="A privacy policy is a legal document that discloses how a company or website collects, uses, and manages the personal data of its customers and visitors"
      />

      <section className="sectionPadding">
        <div className="container privacyContainer">
          <h4>Privacy Policy</h4>
          <p>
            Sikama Financial Services is committed to protecting your privacy.
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            our website.
          </p>
          <h4>1. Introduction</h4>
          <h6>1.1 Overview of Sikama Inc.</h6>
          <p>
            Sikama Financial Services, LLC ("Sikama," "we," "us," or "our") is a
            registered Money Services Business with Financial Crimes Enforcement
            Network (FinCEN) that specializes in providing cross-border
            payments, and P2P remittance services through its website and mobile
            app.
          </p>
          <h6>1.2 Purpose of the Privacy Policy</h6>
          <p>
            The purpose of this Privacy Policy ("Policy") is to inform our users
            ("you," "your," or "user") about our practices related to the
            collection, use, sharing, and protection of your personal
            information. By using our services or accessing our website or
            application, you acknowledge that you have read, understood, and
            agree to the terms of this Policy. If you do not agree with any part
            of this Policy, please do not use our services or access our website
            or application.
          </p>
          <h6>1.3 Scope and Applicability</h6>
          <p>
            This Policy applies to all users who access or use our services,
            including those who visit our website, register for an account, and
            engage in transactions through our platform. It covers all the
            personal information that we collect, use, share, and process in the
            course of providing our services. This Policy does not apply to the
            practices of third-party companies or individuals that we do not
            own, control, employ, or manage, including third-party websites or
            services that may be linked to or integrated with our platform. We
            encourage you to review the privacy policies of these third-party
            entities to understand their information practices.
          </p>
          <h4>2. Information Collection</h4>
          <h6>2.1 Personal Information We Collect</h6>
          <h6>2.1.1 Account Information</h6>
          <p>
            To access our services, users are required to create an account.
            During this process, we collect essential personal information to
            enable service delivery and ensure a secure and personalized
            experience. This information includes:
            <ul>
              <li>
                <strong>Name.</strong> Your full legal name to personalize your
                account.
              </li>
              <li>
                <strong>Date of Birth.</strong> To verify age and ensure
                eligibility for our services.
              </li>
              <li>
                <strong>Mailing Address.</strong> For verification purposes and
                to facilitate any necessary correspondence.
              </li>
              <li>
                <strong>Email Address.</strong> Used as a primary mode of
                communication and account recovery.
              </li>
              <li>
                <strong>Phone Number.</strong> For account verification and to
                provide timely support or alerts.
              </li>
              <li>
                <strong>Government-Issued Identification Information.</strong>{" "}
                Such as a passport or driver's license number, to comply with
                legal requirements and to verify identity.
              </li>
              <li>
                <strong>Financial Account Information.</strong> Including bank
                account numbers and routing numbers, to process transactions
                securely and efficiently.
              </li>
            </ul>
          </p>
          <h6>2.1.2 Transaction Information</h6>
          <p>
            Our platform facilitates transactions, necessitating the collection
            of detailed information to ensure accuracy, security, and regulatory
            compliance. This includes:
            <ul>
              <li>
                <strong>Transaction Amount.</strong> To process and record the
                financial transfer.
              </li>
              <li>
                <strong>Currency.</strong> For accurate conversion and
                reporting.
              </li>
              <li>
                <strong>Transaction Date.</strong> To maintain accurate
                financial records.
              </li>
              <li>
                <strong>Purpose of the Transaction.</strong> To understand user
                needs and comply with financial regulations.
              </li>
              <li>
                <strong>Sender and Recipient Details.</strong> Including names,
                addresses, and account numbers, to securely direct funds and
                confirm identity.
              </li>
            </ul>
          </p>
          <h6>2.1.3 Device and Usage Information</h6>
          <p>
            Interactions with our website or application provide us with data
            critical for improving user experience and security. This includes:
            <ul>
              <li>
                <strong>IP Address.</strong> For location-based customization
                and security monitoring.
              </li>
              <li>
                <strong>Device Type and Browser Type.</strong> To optimize our
                platform for various devices and browsers.
              </li>
              <li>
                <strong>Operating System.</strong> To ensure compatibility and
                secure access.
              </li>
              <li>
                <strong>Referring URLs.</strong> To understand navigation paths
                and improve service offerings.
              </li>
              <li>
                <strong>Interactions with Our Services.</strong> Including pages
                visited, features used, and time spent, to enhance usability and
                content relevance.
              </li>
            </ul>
          </p>
          <h6>2.1.4 Cookies and Tracking Technologies</h6>
          <p>
            We employ cookies and similar tracking technologies to enhance user
            experience, analyze platform performance, and deliver personalized
            content. These include:
            <ul>
              <li>
                <strong>Session Cookies.</strong> Temporary cookies that remain
                in your cookie file until you leave the site, essential for
                maintaining service continuity and session integrity.
              </li>
              <li>
                <strong>Persistent Cookies.</strong> Stored on your device to
                remember your preferences and actions over time, improving your
                return visits and personalized settings.
              </li>
              <li>
                <strong>Analytical Cookies.</strong> Collect aggregate data on
                how users interact with our site, helping us improve
                functionality and user experience.
              </li>
              <li>
                <strong>Functional Cookies.</strong> Enable advanced site
                features and local preferences, such as language settings, by
                remembering your choices.
              </li>
              <li>
                <strong>Advertising Cookies.</strong> Used to deliver
                advertisements more relevant to you and your interests, often
                linked to site functionality provided by third-party
                advertisers.
              </li>
            </ul>
          </p>
          <h6>2.2 Non-Personal Information We Collect</h6>
          <h6>2.2.1 Cookies and Tracking Technologies</h6>
          <p>
            We use cookies and other tracking technologies, such as web beacons
            and pixels, to collect information about browsing activities and
            preferences. This information may include your browsing history,
            search queries, and other non-personal data that helps us understand
            how users interact with our services and tailor our offerings to
            better suit your needs.
          </p>
          <h6>2.2.2 Aggregated and Anonymized Data</h6>
          <p>
            We may collect and process aggregated and anonymized data that does
            not directly identify individual users. This information may be
            derived from your personal information but will not directly or
            indirectly reveal your identity. We may use this aggregated and
            anonymized data for various purposes, such as analyzing usage
            patterns, improving our services, and conducting research.
          </p>
          <h6>2.3 How We Collect Information</h6>
          <h6>2.3.1 Directly from You</h6>
          <p>
            We collect most of your personal information directly when you
            create an account, engage in transactions, or interact with our
            customer support. You may also provide us additional information
            voluntarily through surveys, contests, or other promotional
            activities.
          </p>
          <h6>2.3.2 Automatically from Your Device</h6>
          <p>
            As you access and use our services, we automatically collect certain
            information from your device, such as your IP address, device type,
            browser type, and operating system. We also collect information
            about your interactions with our platform using cookies and other
            tracking technologies.
          </p>
          <h6>2.3.3 From Third-Party Sources</h6>
          <p>
            We may obtain information about you from third-party sources, such
            as credit bureaus, financial institutions, or other service
            providers, to help us verify your identity, assess your
            creditworthiness, or comply with legal and regulatory obligations.
          </p>
          <h4>3. Information Use</h4>
          <h6>3.1 How We Use Your Personal Information</h6>
          <h6>3.1.1 Providing Our Services</h6>
          <p>
            We use your personal information to provide services, including
            creating and managing your account, processing transactions,
            facilitating cross-border payments, and offering foreign exchange
            liquidity and stablecoin trading services. We also use your
            information to provide customer support, respond to your inquiries,
            and address any issues that may arise while using our services.
          </p>
          <h6>3.1.2 Compliance and Legal Obligations</h6>
          <p>
            We are committed to complying with all applicable laws and
            regulations, including those related to anti-money laundering (AML),
            combating the financing of terrorism (CFT), and other financial
            crimes. As such, we use your personal information to verify your
            identity, conduct due diligence, monitor transactions for suspicious
            activities, and report to relevant authorities as required by law.
            We may also use your information to enforce our terms of service,
            protect our rights, and resolve disputes.
          </p>
          <h6>3.1.3 Communicating with You</h6>
          <p>
            We use your personal information to communicate with you regarding
            your account, transactions, or any updates to our services. We may
            send you service-related announcements, technical notices, security
            alerts, or other important messages. We may also use your
            information to provide promotional materials or other marketing
            communications, subject to your preferences and applicable laws.
          </p>
          <h6>3.1.4 Marketing and Advertising</h6>
          <p>
            We may use your personal information to understand your preferences
            and interests, tailor our marketing efforts, and present you with
            relevant offers, promotions, and advertisements. We may also use
            aggregated and anonymized data for market research, analysis, and
            other marketing-related purposes.
          </p>
          <h6>3.1.5 Improving Our Services</h6>
          <p>
            We continually strive to improve our services and enhance your user
            experience. We use your personal information, along with
            non-personal information and aggregated data, to analyze usage
            patterns, identify trends, troubleshoot technical issues, and
            develop new features, products, or services that may interest you.
          </p>
          <h6>3.2 How We Use Non-Personal Information</h6>
          <h6>3.2.1 Analyzing Usage Patterns</h6>
          <p>
            We use non-personal information, such as cookies and other tracking
            technologies, to analyze how users interact with our platform,
            identify trends, and gather demographic information about our user
            base. This analysis helps us understand user preferences and improve
            the overall performance and functionality of our services.
          </p>
          <h6>3.2.2 Service Enhancement</h6>
          <p>
            We use aggregated and anonymized data to identify areas of
            improvement and develop new features, products, or services that may
            interest our users. This information allows us to make data-driven
            decisions and prioritize enhancements that benefit our users and
            align with our business objectives.
          </p>
          <h4>4. Information Sharing and Disclosure</h4>
          <h6>4.1 Sharing with Third Parties</h6>
          <h6>4.1.1 Service Providers</h6>
          <p>
            We may share your personal information with third-party service
            providers that perform services on our behalf, such as payment
            processing, data storage, analytics, marketing, and customer
            support. These service providers are contractually obligated to
            maintain the confidentiality and security of your information and
            are restricted from using your information for any purpose other
            than to provide the services requested by Sikama.
          </p>
          <h6>4.1.2 Business Partners</h6>
          <p>
            We may partner with other companies to offer additional services or
            promotions that may interest our users. In such cases, we may share
            your personal information with these business partners to facilitate
            the provision of these services or promotions, subject to your
            consent and applicable laws. Our business partners are required to
            protect the confidentiality of your information and use it only for
            agreed-upon purposes.
          </p>
          <h6>4.1.3 Legal and Regulatory Authorities</h6>
          <p>
            We may disclose your personal information to law enforcement,
            regulatory agencies, or other government authorities if required by
            law or if we believe that such disclosure is necessary to comply
            with a legal obligation, protect our rights or property, prevent
            fraud or other illegal activities, or ensure the safety of our users
            and the public.
          </p>
          <h4>4.2 Business Transfers</h4>
          <h6>4.2.1 Mergers and Acquisitions</h6>
          <p>
            In the event that Sikama is involved in a merger, acquisition,
            reorganization, or sale of assets, your personal information may be
            transferred to the acquiring entity as part of the transaction. We
            will notify you of any such changes in ownership or control and
            provide you with information about your rights and choices regarding
            your information.
          </p>
          <h6>4.2.2 Asset Sales</h6>
          <p>
            If Sikama sells or transfers a business unit or assets to another
            company, your personal information may be among the assets
            transferred. In such cases, we will ensure that the recipient agrees
            to provide a level of protection for your information consistent
            with this Policy and applicable laws.
          </p>
          <h4>4.3 Other Disclosures</h4>
          <h6>4.3.1 With Your Consent</h6>
          <p>
            We may share your personal information with third parties for
            purposes not described in this Policy, but only with your explicit
            consent. You can withdraw your consent at any time, subject to legal
            or contractual restrictions and reasonable notice.
          </p>
          <h6>4.3.2 To Protect Our Rights and Property</h6>
          <p>
            We may disclose your personal information to protect our rights or
            property, or the rights and property of our users or others when we
            believe in good faith that such disclosure is necessary to prevent
            or investigate illegal activities, enforce our terms of service, or
            respond to claims or legal processes.
          </p>
          <h4>5. Data Retention and Security</h4>
          <h6>5.1 Retention of Your Personal Information</h6>
          <h6>5.1.1 As Long as Necessary</h6>
          <p>
            We retain your personal information for as long as necessary to
            fulfill the purposes for which it was collected, including providing
            our services, complying with legal and regulatory obligations,
            resolving disputes, and enforcing our agreements. The retention
            period may vary depending on the type of information, the specific
            purpose for which it was collected, and the applicable legal or
            regulatory requirements.
          </p>
          <h6>5.1.2 Legal Requirements</h6>
          <p>
            In some cases, we may be required by law or regulation to retain
            your personal information for a specified period of time, such as to
            comply with anti-money laundering (AML) and counter-terrorism
            financing (CTF) rules, tax laws, or other financial reporting
            obligations. We will retain your information for the minimum period
            required by law and securely dispose of it when it is no longer
            needed.
          </p>
          <h4>5.2 Security Measures</h4>
          <h6>5.2.1 Technical Safeguards</h6>
          <p>
            We employ various technical safeguards to protect your personal
            information from unauthorized access, use, disclosure, alteration,
            or destruction. These measures include data encryption in transit
            and at rest, secure network architectures, firewall protection,
            intrusion detection and prevention systems, and regular security
            audits and vulnerability assessments.
          </p>
          <h6>5.2.2 Organizational Measures</h6>
          <p>
            In addition to technical safeguards, we implement organizational
            measures to ensure the confidentiality, integrity, and availability
            of your personal information. These measures include internal
            policies and procedures, staff training and awareness programs,
            access controls, and incident response and management processes. We
            also require our service providers and business partners to maintain
            appropriate security measures and protect the confidentiality of
            your information.
          </p>
          <p>
            Despite our efforts to protect your personal information, no
            security measure is completely foolproof, and we cannot guarantee
            absolute security. In the event of a security breach that affects
            your personal information, we will take appropriate steps to
            mitigate the impact, notify you and the relevant authorities, and
            implement measures to prevent future incidents. We encourage you to
            protect your information as well, such as choosing a strong
            password, keeping your account information confidential, and using
            up-to-date security software on your devices.
          </p>
          <h4>5.3 Information Storage and Protection</h4>
          <h6>5.3.1 Storage Location</h6>
          <p>
            We store your personal information on secure servers located in the
            United States. Our choice of storage location ensures that your data
            is protected under stringent U.S. data protection laws, alongside
            any applicable legislation in your jurisdiction.
          </p>
          <h6>5.3.2 Data Protection Measures</h6>
          <p>
            To safeguard your information from unauthorized access, use,
            disclosure, alteration, or destruction, we have implemented robust
            technical and organizational security measures. These include:
            <ul>
              <li>
                <strong>Encryption.</strong> Your personal data is encrypted
                during transit and at rest, using state-of-the-art encryption
                technologies to ensure that your information is secure and
                private.
              </li>
              <li>
                <strong>Network Security.</strong> We employ advanced network
                security solutions, including firewalls and intrusion detection
                systems, to monitor and protect data flow within and outside our
                network.
              </li>
              <li>
                <strong>Access Control.</strong> Access to personal information
                is strictly limited to authorized personnel who have a
                legitimate business need to access the data. We enforce strict
                access controls and regularly review access rights to ensure the
                highest level of data protection.
              </li>
              <li>
                <strong>Data Minimization.</strong> We adhere to the principle
                of data minimization, ensuring that we only collect and retain
                the minimum amount of personal information necessary for our
                operational needs and legal compliance.
              </li>
              <li>
                <strong>Regular Audits and Assessments.</strong> Our security
                protocols include regular audits and vulnerability assessments
                to identify and remediate potential security gaps promptly,
                ensuring the ongoing protection of your data.
              </li>
              <li>
                <strong>Employee Training and Awareness.</strong> We conduct
                regular training and awareness programs for our employees to
                reinforce the importance of data protection and to ensure they
                understand their roles and responsibilities in safeguarding user
                information.
              </li>
            </ul>
          </p>

          <h6>5.3.3 Third-Party Service Providers</h6>
          <p>
            When we engage third-party service providers to process personal
            information on our behalf, we require them to adhere to equivalent
            security standards and practices. These providers are contractually
            obligated to maintain the confidentiality and security of your data
            and are prohibited from using it for any purpose other than the
            specific services they are providing to Sikama.
          </p>
          <h6>5.3.4 User Responsibility</h6>
          <p>
            We encourage you to use strong, unique passwords for your accounts,
            keep your login information confidential, and ensure that your
            personal devices are protected with up-to-date antivirus and
            anti-malware software.
          </p>
          <h6>5.3.5 Breach Notification</h6>
          <p>
            In the unlikely event of a data breach, we will notify affected
            users and relevant authorities in accordance with applicable laws
            and regulations, providing clear information about the nature of the
            breach, the data involved, the potential impact on individuals, and
            the steps we are taking to address the situation and prevent future
            occurrences.
          </p>

          <h4>6. International Data Transfers</h4>
          <h6>6.1 Cross-Border Data Transfer</h6>
          <p>
            Sikama Inc. operates globally and may transfer, store, and process
            your personal information in countries other than your country of
            residence. These countries may have data protection laws that differ
            from those in your jurisdiction. When we transfer your personal
            information across borders, we ensure that your information is
            protected per this Privacy Policy and applicable laws.
          </p>
          <h6>6.2 Privacy Shield and Other Safeguards</h6>
          <p>
            We may rely on various legal mechanisms to transfer your personal
            information across borders to comply with data protection
            requirements. These mechanisms may include the EU-U.S. Privacy
            Shield Framework, the Swiss-U.S. Privacy Shield Framework, or other
            approved data transfer mechanisms, such as Standard Contractual
            Clauses, Binding Corporate Rules, or approved Codes of Conduct.
          </p>
          <p>
            In the event that we transfer your personal information to countries
            that have not been deemed to provide an adequate level of data
            protection by the relevant authorities, we will implement
            appropriate safeguards to protect your information in accordance
            with this Privacy Policy and applicable laws. By using our services
            or providing your personal information to us, you consent to the
            transfer, storage, and processing of your information in accordance
            with this Privacy Policy and applicable laws.
          </p>
          <h4>7. Your Rights and Choices</h4>
          <h6>7.1 Access and Correction</h6>
          <h6>7.1.1 Access to Your Personal Information</h6>
          <p>
            You have the right to request access to the personal information
            that we hold about you. Upon receipt of a verifiable request, we
            will provide you with a copy of your personal information within the
            timeframes and subject to the exceptions prescribed by applicable
            laws. In some cases, we may charge a reasonable fee to cover the
            costs associated with providing access to your information.
          </p>
          <h6>7.1.2 Correction of Inaccurate or Incomplete Information</h6>
          <p>
            You have the right to request the correction or update of any
            inaccurate or incomplete personal information we hold about you. We
            encourage you to keep your account information up-to-date and
            accurate to ensure the proper functioning of our services and
            compliance with legal and regulatory obligations.
          </p>
          <h4>7.2 Erasure and Restriction</h4>
          <h6>7.2.1 Erasure of Your Personal Information</h6>
          <p>
            You have the right to request the erasure of your personal
            information under certain circumstances, such as when your
            information is no longer needed for the purposes for which it was
            collected, or when you have withdrawn your consent for its
            processing (subject to legal or contractual restrictions and
            reasonable notice). Please note that we may not be able to erase
            your information in all cases, particularly when its retention is
            required to comply with legal and regulatory obligations, enforce
            our agreements, or protect our rights and the rights of others.
          </p>
          <h6>7.2.2 Restriction of Processing</h6>
          <p>
            You have the right to request the restriction of processing of your
            personal information under certain circumstances, such as when you
            contest the accuracy of your information or when the processing is
            unlawful. In such cases, we will only process your information with
            your consent or for the establishment, exercise, or defense of legal
            claims, the protection of the rights of others, or for other limited
            purposes as permitted by law.
          </p>
          <h4>7.3 Data Portability</h4>
          <p>
            You have the right to request the transfer of your personal
            information to another service provider, where technically feasible,
            in a structured, commonly used, and machine-readable format. This
            right applies to the personal information you provided and that we
            process based on your consent or for the performance of a contract.
          </p>
          <h4>7.4 Objection to Processing</h4>
          <p>
            You have the right to object to processing your personal information
            for direct marketing purposes or when the processing is based on our
            legitimate interests. In such cases, we will cease processing your
            information unless we have compelling legitimate grounds to continue
            processing or the processing is necessary to establish, exercise, or
            defend legal claims.
          </p>
          <h4>7.5 Exercising Your Rights</h4>
          <p>
            To exercise any of your rights under this section, please submit a
            request to our Data Protection Officer or designated representative
            using the contact information provided in this Privacy Policy. We
            may require you to verify your identity before processing your
            request. We will respond to your request within the timeframes and
            subject to the exceptions prescribed by applicable laws.
          </p>
          <h4>7.6 Right to Lodge a Complaint</h4>
          <p>
            If you believe that we have not adequately addressed your concerns
            or that we have violated your data protection rights, you have the
            right to lodge a complaint with the relevant data protection
            authority in your jurisdiction. We encourage you to contact us
            first, and we will do our best to resolve your concerns in a timely
            and satisfactory manner.
          </p>
          <h4>7.7 Opting Out of Data Collection and Usage</h4>
          <p>
            We aim to provide you with choices and control over the collection
            and use of your personal information. This section outlines the
            options available to you for managing your data preferences and
            exercising your right to opt-out of specific data collection and
            usage practices.
          </p>
          <h6>7.7.1 Managing Cookies and Tracking Technologies</h6>
          <p>
            <ul>
              <li>
                <strong>Browser Settings.</strong> Most web browsers are set to
                accept cookies by default. If you prefer, you can usually choose
                to set your browser to remove or reject browser cookies. Please
                note that if you choose to remove or reject cookies, this could
                affect the availability and functionality of our services.
              </li>
              <li>
                <strong>Opt-Out Tools.</strong> Some of the analytics and
                advertising cookies we use are offered by third parties. You can
                opt-out of these third-party cookies through their respective
                opt-out tools (e.g., Google Analytics Opt-out Browser Add-on).
              </li>
              <li>
                <strong>Do Not Track.</strong> Some browsers include a "Do Not
                Track" (DNT) feature that signals to websites you visit that you
                do not want to have your online activity tracked. Please note
                that we do not currently respond to DNT signals.
              </li>
            </ul>
          </p>
          <h6>7.7.2 Opting Out of Marketing Communications</h6>
          <p>
            You have the right to opt-out of receiving marketing communications
            from us at any time. You can exercise this right by:
            <ul>
              <li>
                <strong>Email Preferences.</strong> Clicking on the
                "unsubscribe" link provided in the emails we send.
              </li>
              <li>
                <strong>Account Settings.</strong> Adjusting your communication
                preferences in your account settings.
              </li>
              <li>
                <strong>Contacting Us.</strong> Using the contact details
                provided in Section 10 of this Privacy Policy to directly
                request removal from our marketing lists.
              </li>
            </ul>
          </p>
          <h6>7.7.3 Restricting the Processing of Your Personal Information</h6>
          <p>
            Under certain circumstances, you have the right to restrict the
            further processing of your personal information. Such circumstances
            include times when the accuracy of the information is contested,
            processing is unlawful, or if you have objected to processing based
            on our legitimate interests. To request a restriction on processing:
            <ul>
              <li>
                <strong>Contact Us.</strong> Please reach out to our Data
                Protection Officer with a detailed request for restriction. We
                will review your request and respond in accordance with
                applicable data protection laws.
              </li>
            </ul>
          </p>
          <h6>7.7.4 Withdrawing Consent</h6>
          <p>
            Where we rely on your consent to process personal information, you
            have the right to withdraw your consent at any time. Withdrawal of
            consent will not affect the lawfulness of processing based on
            consent before its withdrawal. To withdraw your consent:
            <ul>
              <li>
                <strong>Contact Us.</strong> Please send a clear and concise
                request to our Data Protection Officer, specifying which consent
                you are withdrawing.
              </li>
            </ul>
          </p>
          <h4>8. Third-Party Websites and Services</h4>
          <h4>8.1 Links to Other Websites</h4>
          <p>
            Our platform may contain links to third-party websites not operated
            or controlled by Sikama Inc. Please be aware that when you click on
            these links, you will be directed to the third-party website, which
            may have different privacy policies, terms of service, and security
            measures. We are not responsible for the content, privacy practices,
            or security of any third-party websites or services, and we
            encourage you to review their policies before submitting any
            personal information to them.
          </p>
          <h4>8.2 Third-Party Service Providers</h4>
          <p>
            As this Privacy Policy mentions, we may engage third-party service
            providers to perform certain services on our behalf, such as payment
            processing, data storage, analytics, marketing, and customer
            support. These service providers are contractually obligated to
            maintain the confidentiality and security of your personal
            information and are restricted from using your information for any
            purpose other than to provide the services requested by Sikama.
          </p>
          <h4>8.3 Third-Party Integrations</h4>
          <p>
            Our platform may offer features or functionalities provided by
            third-party service providers or that rely on integrating
            third-party applications or services (e.g., social media sharing
            buttons). When you use these features or functionalities, you may be
            required to share your personal information with the third-party
            service provider or grant them access to your account information.
            We are not responsible for the privacy practices or security of
            these third-party service providers, and we encourage you to review
            their policies before sharing your information or granting them
            access to your account.
          </p>
          <h4>8.4 Disclaimer of Liability</h4>
          <p>
            Sikama Inc. disclaims any liability for the privacy practices,
            security measures, or content of third-party websites, services, or
            applications linked to or integrated with our platform. By using our
            platform and engaging with third-party websites, services, or
            applications, you acknowledge and agree that Sikama Inc. is not
            responsible for any damages, losses, or other liabilities that may
            arise from your interactions with these third parties, and that you
            do so at your own risk.
          </p>
          <h4>9. Changes to Our Privacy Policy</h4>
          <h4>9.1 Periodic Review and Updates</h4>
          <p>
            We reserve the right to periodically review and update this Privacy
            Policy to reflect changes in our information practices, applicable
            laws, and industry standards. When we make material changes to our
            Privacy Policy, we will notify you by posting a prominent notice on
            our platform, emailing the address associated with your account, or
            using other appropriate communication methods, as required by
            applicable law. We encourage you to review this Privacy Policy
            regularly to stay informed about our information practices and your
            privacy rights and choices.
          </p>
          <h4>9.2 Effective Date and Consent</h4>
          <p>
            The effective date of any updates to our Privacy Policy will be
            indicated at the top of this page. By continuing to use our platform
            or services after we have notified you of changes to our Privacy
            Policy, you are indicating your acceptance of these changes and
            consent to the collection, use, disclosure, and processing of your
            personal information in accordance with the revised Privacy Policy.
            If you disagree with the changes, you may choose to discontinue
            using our platform and services.
          </p>
          <h4>9.3 Interpretation and Precedence</h4>
          <p>
            In case of any discrepancies or inconsistencies between different
            language versions of this Privacy Policy, the English version shall
            prevail. If there is any conflict between the terms of this Privacy
            Policy and any applicable laws or regulations, the latter shall take
            precedence, and the relevant provisions of this Privacy Policy shall
            be deemed to be amended or superseded to the extent necessary to
            comply with such laws or regulations.
          </p>
          <h4>10. Contact Information</h4>
          <p>
            If you have any questions, concerns, or comments about our Privacy
            Policy, our information practices, or your rights and choices,
            please do not hesitate to contact our Data Protection Officer or
            designated representative using the following contact information:
          </p>
          <p>
            Sikama Financial Services, LLC 202 North Ceder Ave., Suite 1
            Owatonna, MN 55060 United States Email: privacy@sikamai.com
          </p>
          <p>
            We are committed to addressing your concerns in a timely and
            satisfactory manner. If you believe that we have not adequately
            addressed your concerns or that we have violated your data
            protection rights, you have the right to lodge a complaint with the
            relevant data protection authority in your jurisdiction. We
            encourage you to contact us first, and we will do our best to
            resolve your concerns.
          </p>
          <p>
            Your feedback and satisfaction are important to us, and we are
            dedicated to ensuring your privacy is protected, and your rights are
            respected.
          </p>
        </div>
      </section>

      <section className="sectionPadding mt-5 mobileNone"></section>

      <PreRegisterSection />

      <AppFooter />
    </main>
  );
}

export default PrivacyPage;
