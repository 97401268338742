import React from "react";

function Loading({ msg }) {
  return (
    <div className="loadingContainer">
      <div>
        <div className="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <h6>{msg}</h6>
      </div>
    </div>
  );
}

export default Loading;
