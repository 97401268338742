import {
  MdLocationOn,
  MdOutlineLocalPhone,
  MdOutlineMailOutline,
} from "react-icons/md";
import React, { useEffect, useState } from "react";

import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import BASEURL from "../utils/BASEURL";
import { FaClock } from "react-icons/fa6";
import Loading from "../components/Loading";
import PageTitle from "../components/PageTitle";
import PreRegisterSection from "../components/PreRegisterSection";
import scrollTop from "../utils/scrollTop";
import { toast } from "react-toastify";

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function ContactPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    msg: "",
  });

  useEffect(() => {
    scrollTop();
  }, []);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (emailPattern.test(formDetails.email) && formDetails.msg) {
      console.log(formDetails);
      handleSubmitContact();
    } else {
      toast.error("Please enter valid details");
    }
  };

  const handleSubmitContact = async () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: formDetails.name,
      phone: formDetails.mobile,
      email: formDetails.email,
      message: formDetails.msg,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASEURL}/contact_us_email`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          toast(
            "Thank you for reaching out! Your message has been successfully sent. We appreciate your interest and will get back to you as soon as possible. Have a great day!"
          );
          setFormDetails({
            name: "",
            email: "",
            mobile: "",
            msg: "",
          });
        } else {
          toast.error(result.msg);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  return (
    <main>
      <AppHeader />
      <PageTitle
        heading="Contact Us"
        subheading="Explore our latest blog posts for insightful articles, industry trends, and expert tips to stay informed and inspired."
      />

      {isLoading && (
        <Loading msg="We are sending details to server please wait..." />
      )}

      <section className="sectionPadding">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="contactContainer">
                <div className="row">
                  <div className="col-md-5 p-0">
                    <div className="formContent">
                      <h4>Keep In Touch</h4>
                      <p>
                        When it comes to getting in touch with us, we are
                        committed to providing a seamless and accessible
                        experience. Whether you have questions, feedback, or
                        inquiries, our Contact Us page offers various options,
                        including a contact form, email address, phone number,
                        and links to our social media platforms, ensuring that
                        you can reach us through your preferred method of
                        communication
                      </p>
                      <div className="formTxt">
                        <span>
                          <MdLocationOn />
                        </span>
                        <p>
                          Sikama Financial Services, LLC 202 North Ceder Ave.,
                          Suite 1 Owatonna, MN 55060 United States.
                        </p>
                      </div>

                      <div className="formTxt">
                        <span>
                          <MdOutlineMailOutline />
                        </span>
                        <p>support@sikamai.com</p>
                      </div>
                      <div className="formTxt">
                        <span>
                          <FaClock />
                        </span>
                        <p>Monday to Friday, 9:00 AM to 5:00 PM Eastern Time</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="formWrapper">
                      <h4>send your query</h4>
                      <form action="">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="formGroup">
                              <label className="formLabel">Name</label>
                              <input
                                type="text"
                                className="formControl"
                                name="name"
                                value={formDetails.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/* col md 4 close here */}
                          <div className="col-md-4">
                            <div className="formGroup">
                              <label className="formLabel">Email</label>
                              <input
                                type="email"
                                className="formControl"
                                name="email"
                                value={formDetails.email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/* col md 4 close here */}
                          <div className="col-md-4">
                            <div className="formGroup">
                              <label className="formLabel">Mobile</label>
                              <input
                                type="number"
                                className="formControl"
                                name="mobile"
                                value={formDetails.mobile}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/* col md 4 close here */}
                          <div className="col-md-12">
                            <div className="formGroup">
                              <label className="formLabel">Message</label>
                              <textarea
                                className="textarea"
                                style={{ height: 140 }}
                                name="msg"
                                value={formDetails.msg}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>
                          {/* col md 12 close here */}
                          <div className="col-md-12">
                            <div className="formBtns">
                              <button
                                className="btn btnActive"
                                type="button"
                                onClick={() => handleSubmit()}
                              >
                                Submit Form
                              </button>
                            </div>
                          </div>
                          {/* col md 12 close here */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionPadding mt-5 mobileNone"></section>

      <PreRegisterSection />

      <AppFooter />
    </main>
  );
}

export default ContactPage;
