import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import * as React from "react";

import {
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  createHashRouter,
  useRouter,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import AboutPage from "./pages/AboutPage";
import BlogDetails from "./pages/BlogDetails";
import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import ModalContext from "./utils/ModalContext";
import PreRegisterForm from "./components/PreRegisterForm";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";

const router = createHashRouter(
  [
    {
      path: "/",
      Component: HomePage,
    },
    {
      path: "about",
      Component: AboutPage,
    },
    {
      path: "contact",
      Component: ContactPage,
    },
    {
      path: "terms_conditions",
      Component: TermsPage,
    },
    {
      path: "privacy_policy",
      Component: PrivacyPage,
    },
    {
      path: "blogs",
      Component: BlogPage,
    },
    {
      path: "blog_detail?",
      Component: BlogDetails,
    },
  ],
  {
    basename: "/",
  }
);

function App() {
  const [toggleModal, setToggleModal] = React.useState(false);

  return (
    <ModalContext.Provider value={{ toggleModal, setToggleModal }}>
      <PreRegisterForm />
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ModalContext.Provider>
  );
}

export default App;
