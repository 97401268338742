import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import appLogo from "../images/sikama_logo.svg";
import ModalContext from "../utils/ModalContext";

function AppHeader() {
  const [openMenu, setOpenMenu] = useState(false);
  const { setToggleModal } = useContext(ModalContext);

  return (
    <header className="topHeader">
      <div className="container">
        <nav className="navbar">
          <Link to="/" className="logoContainer">
            <img
              alt=""
              src={appLogo}
              className="appLogo"
              width={50}
              height={30}
            />
          </Link>

          <div className="mobileMenu" onClick={() => setOpenMenu(!openMenu)}>
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </div>

          <div
            className={
              openMenu
                ? "navbarContent menuWraper active"
                : "navbarContent menuWraper"
            }
          >
            <ul className="linkWrapper">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blogs" className="nav-link">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => setToggleModal(true)}
                  className="nav-link registerBtn"
                >
                  Pre Register
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default AppHeader;
