import React, { useEffect } from "react";
import { blogImg1, blogSection2, clockIcon, user } from "../utils/images";

import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import ComingSoon from "../components/ComingSoon";
import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import PreRegisterSection from "../components/PreRegisterSection";
import blogContent from "../utils/blogContent";
import scrollTop from "../utils/scrollTop";

function BlogPage() {
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <main>
      <AppHeader />

      <PageTitle
        heading="Blog"
        subheading="Explore our latest blog posts for insightful articles, industry trends, and expert tips to stay informed and inspired."
      />

      {/* <section className="comingSoonSection" style={{ borderWidth: 0 }}>
        <div className="row">
          <div className="col-md-7 p-0">
            <div className="hero-image-desktop">
              <img
                className="img-fluid"
                src={blogSection2}
                alt="Femail athlete squinting towards the camera."
              />
            </div>
          </div>
          <div className="col-md-5 my-auto">
            <article className="text-info">
              <h2>We're</h2>
              <h2>
                Coming
                <br />
                Soon
              </h2>
              <p>
                We are working hard to bring you a new and improved financial
                block page that will provide you with all the information you
                need to make informed financial decisions. Our new page will
                feature up-to-date financial news, expert analysis, and helpful
                tools to help you manage your finances. Stay tuned for more
                updates and be sure to check back soon!
              </p>
            </article>
          </div>
        </div>
      </section> */}

      <section className="sectionPadding">
        <div className="container">
          <div className="row">
            {blogContent.reverse().map((item) => (
              <div className="col-md-4">
                <div className="singleBlogCard">
                  <Link
                    className="absoluteLink"
                    to={`/blog_detail?blog=${item.blogTitle
                      .split(" ")
                      .join("-")}`}
                  />
                  <img
                    className="blogImg"
                    src={item.blogBanner}
                    width={300}
                    height={200}
                  />
                  <div className="blogContent">
                    <h4>{item.blogTitle.split(" ").slice(0, 6).join(" ")}</h4>
                    <p>{item.para.split(" ").slice(0, 19).join(" ")}</p>
                    <span className="readMore">Read More</span>
                  </div>
                </div>
              </div>
            ))}

            {/* {new Array(9).fill(0).map((item, index) => (
              <div className="col-md-4">
                <div className="singleBlogCard">
                  <Link
                    className="absoluteLink"
                    to={
                      "/blog_detail?blog_title=Lorem-ipsum-dolor-sit-amet-consectetur+" +
                      index
                    }
                  />
                  <img
                    className="blogImg"
                    src={blogImg1}
                    width={300}
                    height={200}
                  />
                  <div className="blogContent">
                    <div className="blogInfo">
                      <span>
                        <img alt="" src={user} width={80} height={30} />
                        Admin
                      </span>
                      <span>
                        <img alt="" src={clockIcon} width={80} height={30} />
                        January 14, 2021
                      </span>
                    </div>
                    <h4>Lorem ipsum dolor sit amet, consectetur</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur libero vitae Lorem
                      ipsum dolor sit amet, consectetur libero vitae..
                    </p>
                    <span className="readMore">Read More</span>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </section>

      <section className="sectionPadding mobileNone"></section>

      <PreRegisterSection />

      <AppFooter />
    </main>
  );
}

export default BlogPage;
