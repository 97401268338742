import {
  exchangeRate,
  how1,
  how2,
  how3,
  multiCurrency,
  testiUser1,
  testiUser2,
  testiUser3,
  testiUser4,
  testiUser5,
  why1,
  why2,
  why3,
} from "./images";

export const chooseData = [
  {
    id: 0,
    icon: why1,
    heading: "Reliability",
    para: "The fundamental aim of reliability in Sikama Financial Services revolves around establishing and maintaining a robust operational framework that ensures the secure and timely transfer of funds for customers. By ensuring the accurate and prompt execution of transactions, we aim to build and nurture customer trust, fostering enduring relationships and unwavering loyalty.",
  },
  {
    id: 1,
    icon: why2,
    heading: "Speed and Efficiency",
    para: "Sikama Financial Services will implement real-time transaction processing capabilities to enable instant customer money transfers. Establishing a streamlined operational framework encompassing swift transaction processing, optimized data encryption, and meticulous adherence to regulatory guidelines is poised to ensure timely and secure fund transfers for customers through our platform.",
  },
  {
    id: 2,
    icon: exchangeRate,
    heading: "Competitive Exchange Rates and Fees",
    para: "We aim to put customers at the center of service management by understanding their needs, preferences, and pain points. This will involve collecting customer feedback, conducting surveys, and analyzing data to improve the services offered continuously.",
  },
  {
    id: 2,
    icon: multiCurrency,
    heading: "Multi-Currency Support",
    para: "Enabling multi-currency support is a central goal of Sikama Financial Services to enhance financial systems. By facilitating transactions and accounts in various currencies, Sikama Financial Services promotes seamless global transactions, fostering international business growth and user convenience.",
  },
];

export const howContent = [
  {
    icon: how1,
    title: "Download",
    para: "Download the Sikama App from the Google Play Store for Android devices and in App Store for iOS devices.",
  },
  {
    icon: how2,
    title: "Setup Profile and Bank",
    para: "Onboard unto the App after installation by entering simple user information connect your bank account start sending and chatting.",
  },
  {
    icon: how3,
    title: "Start Transactions",
    para: "To initiate transactions seamlessly, utilize the Start-Transaction command to manage a series of commands as a unit, ensuring data consistency and the ability to commit or roll back changes as needed.",
  },
];

export const testimonialData = [
  {
    name: "John Smith",
    profile: testiUser1,
    designation: "CEO, ABC Corporation",
    desc: "Sikama Financial Services has revolutionized the way I manage transactions. The app's user-friendly interface and secure platform have made sending and receiving money effortless. I highly recommend it to anyone looking for a reliable financial app.",
  },
  {
    name: "Emily Johnson",
    profile: testiUser2,
    designation: "Digital Marketer",
    desc: "As a freelancer, Sikama Financial Services has simplified my payment processes. Its seamless integration with various payment methods and quick transaction processing have made my work hassle-free. I'm extremely satisfied with the app's efficiency.",
  },
  {
    name: "David Lee",
    profile: testiUser3,
    designation: "Owner, XYZ Cafe",
    desc: "Using Sikama Financial Services has been a game-changer for my small business. It has provided a convenient way for my customers to make payments, and the instant transfer feature has significantly improved our cash flow.",
  },
  {
    name: "Sarah Miller",
    profile: testiUser4,
    designation: "College Student",
    desc: "Sikama Financial Services has made splitting bills and transferring money among friends incredibly easy. Its intuitive design and quick fund transfer have simplified our group expenses. I recommend it to all students for seamless money management.",
  },
  {
    name: "Saram Northen",
    profile: testiUser5,
    designation: "College Student",
    desc: "Sikama Financial Services has made splitting bills and transferring money among friends incredibly easy. Its intuitive design and quick fund transfer have simplified our group expenses. I recommend it to all students for seamless money management.",
  },
];
