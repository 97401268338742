import React, { useContext } from "react";
import { appIcon, appPicture } from "../utils/images";
import ModalContext from "../utils/ModalContext";

function PreRegisterSection() {
  const { setToggleModal } = useContext(ModalContext);
  return (
    <section className="appSection">
      <div className="container">
        <div className="row">
          <div className="col-md-6 my-auto">
            <div className="appBanner">
              <h4>Pre Register for SIKAMA</h4>
              <p>
                Pre-register for Sikama International's upcoming events and stay
                informed about the latest industry trends, product updates, and
                company news. Don't miss out on our engaging webinars,
                workshops, and conferences designed to enhance your knowledge
                and experience with Sikama's cutting-edge solutions.
              </p>
              <button onClick={() => setToggleModal(true)}>
                <img alt="" src={appIcon} />
                <h5>
                  Pre Register to <span>SIKAMA</span>
                </h5>
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="appPicture">
              <img alt="" src={appPicture} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreRegisterSection;
