import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { blogImg1, clockIcon, user } from "../utils/images";

import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import PageTitle from "../components/PageTitle";
import PreRegisterSection from "../components/PreRegisterSection";
import blogContent from "../utils/blogContent";
import scrollTop from "../utils/scrollTop";

function BlogDetails() {
  const [searchParams] = useSearchParams();
  let blogName = searchParams.get("blog");
  const [blogDetails, setBlogDetails] = useState({});

  useEffect(() => {
    scrollTop();

    getBlogDetails();
  }, [blogName]);

  const getBlogDetails = async () => {
    let exists = blogContent.filter(
      (item) => item.blogTitle == blogName.split("-").join(" ")
    );
    setBlogDetails(exists[0]);
  };

  return (
    <main>
      <AppHeader />

      <section className="sectionPadding blogDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <img
                alt=""
                src={blogDetails.blogBanner}
                className="img-fluid blogFull"
              />
              <h4 className="blogTitle">{blogDetails.blogTitle}</h4>
              {/* <div className="blogShortInfo">
                <div className="shareIcon">
                  <FacebookShareButton url={searchParams.get("blog_title")}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={searchParams.get("blog_title")}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton url={searchParams.get("blog_title")}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div> */}
              {blogDetails?.content?.map((item) => (
                <div className="contentCard">
                  <h6>{item.heading}</h6>
                  <p>{item.para}</p>
                </div>
              ))}
            </div>

            <div className="col-md-4" style={{ marginLeft: "auto" }}>
              <h4 className="recentBlogHeading">Recent Blogs</h4>

              {blogContent.reverse().map((item) => (
                <div className="singleBlogCard">
                  <Link
                    className="absoluteLink"
                    to={`/blog_detail?blog=${item.blogTitle
                      .split(" ")
                      .join("-")}`}
                  />
                  <img
                    className="blogImg"
                    src={item.blogBanner}
                    width={300}
                    height={200}
                  />
                  <div className="blogContent">
                    <h4>{item.blogTitle.split(" ").slice(0, 6).join(" ")}</h4>
                    <p>{item.para.split(" ").slice(0, 20).join(" ")}</p>
                    <span className="readMore">Read More</span>
                  </div>
                </div>
              ))}

              {/* {new Array(3).fill(4).map((item) => (
                <div className="singleBlogCard">
                  <Link className="absoluteLink" to={"/blog_detail?"} />
                  <img
                    className="blogImg"
                    src={blogImg1}
                    width={300}
                    height={200}
                  />
                  <div className="blogContent">
                    <h4>Lorem ipsum dolor sit amet, consectetur</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur libero vitae Lorem
                      ipsum dolor sit amet, consectetur libero vitae..
                    </p>
                    <span className="readMore">Read More</span>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </section>

      <section className="sectionPadding">
        <div className="container"></div>
      </section>

      <PreRegisterSection />

      <AppFooter />
    </main>
  );
}

export default BlogDetails;
