import React, { useContext, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import ModalContext from "../utils/ModalContext";
import { toast } from "react-toastify";
import { discoverBanner } from "../utils/images";
import BASEURL from "../utils/BASEURL";
import Loading from "./Loading";

const namePattern = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const mobilePattern = /^[6-9]\d{9}$/;

function PreRegisterForm() {
  const { toggleModal, setToggleModal } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (
      namePattern.test(formDetails.name) &&
      emailPattern.test(formDetails.email) &&
      mobilePattern.test(formDetails.mobile)
    ) {
      console.log(formDetails);
      handlePreRegister();
    } else {
      toast.error("Please enter valid details");
    }
  };

  const handlePreRegister = async () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: formDetails.name,
      phone: formDetails.mobile,
      email: formDetails.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASEURL}/preregister`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setToggleModal(false);
          toast(
            "Congratulations! You're now pre-registered. We'll keep you updated on all the latest developments. Get ready for an amazing experience ahead!"
          );
          setFormDetails({
            name: "",
            email: "",
            mobile: "",
          });
        } else {
          toast.error(result.msg);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  return (
    <div className={toggleModal ? "modalWrapper active" : "modalWrapper"}>
      {isLoading && (
        <Loading msg="We are sending details to server please wait..." />
      )}
      <div className="modalContainer">
        <div className="modalContent">
          <div className="modalHead">
            <h4>Pre Register</h4>
            <button
              onClick={() => {
                setToggleModal(false);
                setFormDetails({
                  name: "",
                  email: "",
                  mobile: "",
                });
              }}
            >
              <IoCloseOutline />
            </button>
          </div>
          <div className="modalBody">
            <div className="formWrapper">
              <form action="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="discoverPicture">
                      <img alt="" src={discoverBanner} width={80} height={30} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Enter your details in the below form and we will let you
                      know as soon as we launch our app.
                    </p>
                    <div className="formGroup">
                      <label className="formLabel">Name</label>
                      <input
                        type="text"
                        name="name"
                        className="formControl"
                        value={formDetails.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formGroup">
                      <label className="formLabel">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="formControl"
                        value={formDetails.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formGroup">
                      <label className="formLabel">Mobile</label>
                      <input
                        type="number"
                        name="mobile"
                        className="formControl"
                        value={formDetails.mobile}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formBtns">
                      <button
                        className="btn btnActive"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Submit Form
                      </button>
                      {/* <button className="btn" type="reset">
                        Reset Form
                      </button> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modalFooter"></div>
        </div>
      </div>
    </div>
  );
}

export default PreRegisterForm;
